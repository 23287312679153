.App {
	text-align: center;
}

.App-header {
	background-color: #282c34;
	min-height: 150px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 30px;
	color: white;
	margin-bottom: 24px;
}

.restartIcon {
	width: 32px;
	position: absolute;
	top: 12px;
	left: 12px;
	z-index: 4;
	cursor: pointer;
}

h1 {
	font-weight: bold;
	font-size: 30px;
}

.App-link {
	color: #61dafb;
}

.App-small {
	font-size: calc(10px + 1vmin);
}

button {
	padding: 6px 12px;
	font-size: 16px;
	background-color: #282c34;
	color: white;
	border-radius: 2px;
	border: none;
	cursor: pointer;
}

.copyButtons {
	margin-bottom: 24px;
}

textarea {
	border: 1px solid #282c34;
	padding: 12px;
	border-radius: 2px;
}

.clear {
	font-weight: bold;
}

.small {
	font-style: italic;
	font-size: 14px;
}

.header {
	flex-direction: row;
	padding-top: 32px;
	padding-bottom: 32px;
}

.headerItem {
	align-items: center;
	justify-content: center;
}

.headerItem img {
	margin: 4px;
	cursor: pointer;
	border-color: white;
	border-radius: 12px;
	border-width: 2px;
	width: 300px;
	max-width: 40%;
}

.headerItem p {
	font-size: 18px;
}
